// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-bewerber-js": () => import("./../../../src/pages/bewerber.js" /* webpackChunkName: "component---src-pages-bewerber-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-obt-gruppe-js": () => import("./../../../src/pages/obt-gruppe.js" /* webpackChunkName: "component---src-pages-obt-gruppe-js" */),
  "component---src-pages-products-abacus-hr-js": () => import("./../../../src/pages/products/abacusHR.js" /* webpackChunkName: "component---src-pages-products-abacus-hr-js" */),
  "component---src-pages-products-datenmigrationen-js": () => import("./../../../src/pages/products/datenmigrationen.js" /* webpackChunkName: "component---src-pages-products-datenmigrationen-js" */),
  "component---src-pages-products-hrmobil-js": () => import("./../../../src/pages/products/hrmobil.js" /* webpackChunkName: "component---src-pages-products-hrmobil-js" */),
  "component---src-pages-products-hrportal-js": () => import("./../../../src/pages/products/hrportal.js" /* webpackChunkName: "component---src-pages-products-hrportal-js" */),
  "component---src-pages-products-hrprozesse-js": () => import("./../../../src/pages/products/hrprozesse.js" /* webpackChunkName: "component---src-pages-products-hrprozesse-js" */),
  "component---src-pages-products-hrreporting-js": () => import("./../../../src/pages/products/hrreporting.js" /* webpackChunkName: "component---src-pages-products-hrreporting-js" */),
  "component---src-pages-products-org-js": () => import("./../../../src/pages/products/org.js" /* webpackChunkName: "component---src-pages-products-org-js" */),
  "component---src-pages-products-payroll-js": () => import("./../../../src/pages/products/payroll.js" /* webpackChunkName: "component---src-pages-products-payroll-js" */),
  "component---src-pages-products-prozessmanagement-js": () => import("./../../../src/pages/products/prozessmanagement.js" /* webpackChunkName: "component---src-pages-products-prozessmanagement-js" */),
  "component---src-pages-products-time-js": () => import("./../../../src/pages/products/time.js" /* webpackChunkName: "component---src-pages-products-time-js" */),
  "component---src-pages-products-xpert-js": () => import("./../../../src/pages/products/xpert.js" /* webpackChunkName: "component---src-pages-products-xpert-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

